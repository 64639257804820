import React from "react"
import { IoIosHome } from 'react-icons/io';
import { Link } from 'gatsby';

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
        <SEO title="404: Not found" />
        <div className="measure-wide center tc">
            <h1 className="f1 f-headline-l code mb3 fw9 dib tracked-tight">Not Found</h1>
            <h2 className="f1-l fw1">Sorry, we can't find the page you are looking for.</h2>
            <Link to="/" title="Go Home" className="ttl tracked mt5 no-underline near-white bg-animate bg-near-black hover-bg-gray inline-flex items-center ma2 tc br2 pa2">
                <IoIosHome className="dib h2 w2"/>
                <span className="f6 ml3 pr2">Go Home</span>
            </Link>
        </div>
  </Layout>
)

export default NotFoundPage
