/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { Location } from '@reach/router';

const httpsUrl = (url) => (typeof url === 'string' ? url
    .replace('https:', '')
    .replace('http:', '')
    .replace(/^\/\//, 'https://') : null);

function SEO({ description, lang, meta, keywords, title, thumbnailUrl }) {
  const { site, thumbnail } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
          }
        }
        thumbnail: file(name: { eq: "katie" }) {
            childImageSharp {
                fixed(width: 400) {
                    src
                }
            }
        }
      }
    `
  )

  const siteUrl = site.siteMetadata.siteUrl;
  const metaDescription = description || site.siteMetadata.description
  const defaultThumbnail = `${siteUrl}${thumbnail.childImageSharp.fixed.src}`;

  return (
    <Location>
        {({ location }) => (
            <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
                {
                name: `description`,
                content: metaDescription,
                },
                {
                    name: 'image',
                    content: httpsUrl(thumbnailUrl) || defaultThumbnail,
                },
                {
                    property: 'og:url',
                    content: location.href,
                },
                {
                    property: `og:title`,
                    content: `${title} | ${site.siteMetadata.title}`,
                },
                {
                    property: 'og:description',
                    content: metaDescription,
                },
                {
                    property: 'og:image',
                    content: httpsUrl(thumbnailUrl) || defaultThumbnail,
                },
                {
                    property: 'og:type',
                    content: 'website',
                },
                {
                    property: 'og:locale',
                    content: 'en_GB',
                },
                {
                name: `twitter:card`,
                content: `summary`,
                },
                {
                name: `twitter:creator`,
                content: site.siteMetadata.author,
                },
                {
                    name: 'twitter:site',
                    content: site.siteMetadata.author,
                },
                {
                name: `twitter:title`,
                content: `${title} | ${site.siteMetadata.title}`,
                },
                {
                name: `twitter:description`,
                content: metaDescription,
                },
                {
                    name: 'twitter:image',
                    content: httpsUrl(thumbnailUrl) || defaultThumbnail,
                },
            ]
                .concat(
                keywords.length > 0
                    ? {
                        name: `keywords`,
                        content: keywords.join(`, `),
                    }
                    : []
                )
                .concat(meta)}
            />
        )}
    </Location>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
