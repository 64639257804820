import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import rot from 'rot';

const emailAddress = 'katie@teamberry.io';

const Layout = ({ children }) => (
  <Fragment>
    <main className="bg-white">{children}</main>
    <footer className="measure tc f4 f3-ns center mv5 black-70 black-70">
        <a href={`mailto:__ENCRYPTED__${rot(`${emailAddress.replace('@', '_AT_')}`, 13)}`} className="link b f3 f2-ns dim black-70 lh-solid">{emailAddress}</a>
        <p className="f6 db b ttu lh-solid mt2">&copy; {new Date().getFullYear()} Katie Berry</p>
    </footer>
    </Fragment>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
